<ion-content>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start" (click)="backToWall()" class="cancel">&nbsp;&nbsp;<ion-icon name="chevron-back-outline"></ion-icon></ion-buttons>
      <ion-title>Likes</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-list lines="none">
    <ion-item lines="none" *ngFor="let username of likes">
      <div class="list-items">
        <div class="avatar-names-div">
          <ion-avatar>
            <img *ngIf="image" id="avatar" src="{{username[2]}}">
            <img *ngIf="!image" id="avatar" src="../../../assets/avatar.svg">
          </ion-avatar>
          <div class="username-info">
            <ion-label class="username-label-username">{{username[0]}}</ion-label>
            <ion-label class="username-label-family-name">{{username[1]}}</ion-label>
          </div>
        </div>
        <div class="chip-div">
          <ion-chip position="end" (click)="viewProfile(username)" color="primary">View Profile<ion-icon name="chevron-forward-outline" size="small"></ion-icon></ion-chip>
        </div>
      </div>
    </ion-item>
  </ion-list>

  </ion-content>
