

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon (click)="backToProfile()" size="small" name="chevron-back-outline"></ion-icon>Back
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
    <ion-button position="end" expand="full" (click)="signOut()">Sign Out</ion-button>
</ion-content>