
  <ion-content>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" (click)="backToTimeline()" class="cancel">&nbsp;&nbsp;Cancel</ion-buttons>
        <ion-title>Finish your post</ion-title>
        <ion-buttons slot="end" (click)="closeModal(postImageForm.value)" class="update">Post&nbsp;&nbsp;</ion-buttons>
      </ion-toolbar>
    </ion-header>
    <img *ngIf="isImage" class="photo-taken" [src]="src">
    <video *ngIf="browser === 'chrome' && !isImage" [src]="src" class="video-div" width="100%" height="100%" autoplay="autoplay" controls="true" preload="metadata" muted playsinline></video>
    <video *ngIf="browser === 'safari' || browser === 'other' && !isImage" class="video-div" width="100%" height="100%" autoplay="autoplay" controls="true" preload="metadata" muted playsinline>
      <source [src]="src" type="video/mp4">
    </video>
    <form [formGroup]="postImageForm" (ngSubmit)="closeModal(postImageForm.value)">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="12" size-md="12" size-sm="12" size="12">
            <ion-item lines="none">
              <ion-textarea class="comment-textarea" lines="none" placeholder="Add a description..." formControlName="description" autoGrow="true"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </ion-content>
