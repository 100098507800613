

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" (click)="backToTimeline()" class="cancel">&nbsp;&nbsp;Cancel</ion-buttons>
    <ion-title>Add content</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!submitted">
    <!-- <ion-grid class="grid-text">
      <ion-col>
        <ion-row class="button-row">
          <div class="photo-add-div">
            <h4><strong>Add a photo to the timeline</strong></h4>
            <ion-fab-button color="dark" (click)="addPhotoToGallery()" class="camera-button">
              <ion-icon name="camera"></ion-icon>
            </ion-fab-button>
          </div>
        </ion-row>
      </ion-col>
    </ion-grid> -->
    <input hidden type="file" #filepicker (change)="fileSelected($event)" />
  
    <ion-fab vertical="center" horizontal="center" slot="fixed">
      <ion-fab-button>
        Add content<ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-fab-list>
        <ion-fab-button (click)="addFile()">
          <ion-icon name="document"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  </div>



  <div *ngIf="submitted">
    <div class = "container"></div>
  </div>
</ion-content>


